.sidebar{
   position: fixed;
   top: 0;
   left: 0;
   width: 300px;
   height: 100%;
   background: var(--theme-bg-2);
   padding: 6px 14px;
   transition: width 500ms ease;
   z-index: 100;
}

.sidebar-item{
   padding: 10px 8px;
   text-decoration: none !important;
   font-size: medium;
   display: block;
   color: var(--theme-text-2);
   transition: none 300ms ease;
   background-color: var(--theme-bg-2) ;
   border-radius: 5px;
   transition-property: filter;
   font-size: large;
}
.sidebar-btns{
   position: absolute;
   bottom: 50px;
}
.sidebar-btns button{
   margin: 0 5px;
}
.sidebar-item:hover{
   border-bottom: 1px solid var(--theme-shadow);

   filter:brightness(0.93);   

}

.content{
   margin-left: 328px;
   padding: 20px;
}

.sidebar-item.active{
   background: rgba(74, 74, 74, 0.295);
   transition: background 300ms ease;
   border-radius: 5px;
}