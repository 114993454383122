#assessment-instruction-header{
    font-size: 20px;
    animation: fadeIn 1s;
}
@keyframes fadeIn{
    0% {opacity: 0; }
    100% {opacity: 1;}
}


ol li {
    list-style: square;
}

ol li::marker {
    animation-name: GFG;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}



@keyframes GFG {
    from {
        color: black;
        font-size: 10px;
    }

    to {
        font-size: 20px;
        color: green;
    }
}
#assessment-header
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #80cc94 100%,
    #40a506 90%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-size: 50px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

#assessment-instruction-header
{
  animation: fadeIn 3s;
  color: #329f0ad3;
  display: block;
  font-size: 2em;
  font-weight: 900;
  position: relative;
  text-align: center;
  
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes rattle
{
  0% { margin-top: 0; margin-left: 0; }
  10% { margin-top: -5px; margin-left: 0; }
  20% { margin-top: 0; margin-left: -5px; }
  30% { margin-top: 5px; margin-left: 0; }
  40% { margin-top: 0; margin-left: 5px; }
  50% { margin-top: -2px; margin-left: 0; }
  60% { margin-top: 0; margin-left: -2px; }
  70% { margin-top: 2px; margin-left: 0; }
  80% { margin-top: 0; margin-left: 2px; }
  90% { margin-top: -1px; margin-left: 0; }
  100% { margin-top: 0; margin-left: 0; }
}
#start-button{
  margin-top: 20px;
  background-color: #80cc94;
  color: white;
  width: 100px;
  font-size: 15px;
}