.scores {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 50%;
      height: 50px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 0 4px 0 var(--theme-border);
      margin-bottom: 20px;
      background-color: var(--theme-card);


}
.scores:last-child{
      margin-bottom: 0;
}
.about {
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 0px 6px 0px var(--theme-border);
      min-width: 50%;
      background-color: var(--theme-card);
}

.score-cont {
      min-width: 25%;
}

.profile{
      display: flex;
}

.edit-btn{
      float: right;
      cursor: pointer;
      color: var(--theme-accent-2);
}

.profile textarea{
      min-height: 50;
      min-width: 200px;
      resize: none;
      box-shadow: 0px 0px 4px 0px var(--theme-border);
      background-color: var(--theme-bg);
      outline: none;
      border-radius: 8px;
      padding: 10px;
      color: var(--theme-text);
      border: none;
      /* box-shadow: 0 0 6px 0 var(--theme-shadow-2); */
}

.profile input{
      min-width: 500px;
      box-shadow: 0px 0px 4px 0px var(--theme-border);
      background-color: var(--theme-bg);
      outline: none;
      border-radius: 8px;
      padding: 10px;
      /* box-shadow: 0 0 6px 0 var(--theme-shadow-2); */
      font-weight: bold;
      font-size: larger;
      color: var(--theme-text);
      border: none;
}

.profile .edit{
      margin-top: 20px;
}

.profile h1{
      margin: 0;
}
.profile p{
      align-content: center;
}
.score-cont p{
      align-content: center;
}

.admin-container {
      margin-left: 20px;
      display:flex;
      flex-direction: column;
      gap:20px;
      max-width: 38%;
}
.description-container{
      max-width:100%;
}

.score-cont-admin{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 20px;
      align-items: center;
}

.title{
      font-size:40px;
}
