.buttons{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 40px;
    /* border: solid black 2px; */
    margin-bottom: 100px;
    background-color: var(--theme-card);
    border: 1px solid var(--theme-accent);
    border-radius: 8px;
    
}
.buttons:hover{
    cursor: pointer;
}

body.dark .buttons img{
   filter: invert(1) drop-shadow(0 0 5px var(--theme-shadow-2));
}

.header{
   text-align: center;
   margin-left: 40px;
   color: black;
}


