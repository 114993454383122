.dropbar{
   overflow-y:auto ;
   flex-grow: 1;
   flex-basis: 0;
   box-shadow: 0 0 2px 0 var(--theme-border);
   border-radius: 8px;
   margin: 0 10px;
   max-height: 100%;
}

.challenger{
   display: flex;
   max-height: 75%;
}

.dragcard{
   border-radius: 8px;
   cursor: grab;
   overflow: hidden;
   height: 0;
   margin: 0;
   display: none;
}
.dragcard.show{
   padding: 10px;
   border: 1px solid var(--theme-shadow-2);
   margin: 5px 0;
   height: auto;
   transform-origin: center center ;
   display: block;
}
.dragcard.draggedAnim{
   animation: cardIn 0.1s ease-in forwards ;
}

@keyframes cardIn {  
   from{
      transform: scale(0.95);
   }
   to{
      transform: scale(1);
   }
   
}

.dragcard:hover{
   /* box-shadow: 0 0 1px 0 var(--theme-accent); */
   border-color: var(--theme-accent);
}
.dragcard.up{
   border-top: 2px solid;
   margin-top: 20px;
   transition: margin 0.1s ease-in;
}
.dragcard.down{
   margin-bottom: 20px;
   border-bottom: 2px solid;
   transition: margin 0.1s ease-in;
}
.dragItems{
   padding: 10px;
   overflow-y: auto;
   max-height: calc(100% - 102px);
}
/* 
.dragcard.active{
   background: rgba(74, 74, 74, 0.295);
   cursor: grabbing;
} */
.bar-subtitle{
   margin: 3px 40px;
   color: var(--theme-text-3);
   font-size: 0.8em;
}



#n-btn{
   margin: 10px;
   float: right;
   min-width: 200px;
   font-size: 18px;
   font-family: "Galactic Indifference Bold";
   letter-spacing: 0.5px;
}

#title{
   text-align: center;
   font-size: 24px;
   font-family: "Galactic Indifference Bold";
   margin: 10px 0;
   padding-bottom: 5px;
   border-bottom: 1px solid var(--theme-border);
   /* border-radius: 8px; */
}

#directions{
   font-size: 20px;
   text-align: center;
   font-family: "Galactic Indifference Bold";

}


