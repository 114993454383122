.form-input{
   margin: 25px 5px;
}

.login{
   position: absolute;
   top: 40%;
   left: 50%;
   transform: translate(-50%, -50%);
   background: var(--theme-card);
   padding: 20px;
   border-radius: 8px;
   text-align: center;
   box-shadow: 0 0 8px 0 var(--theme-accent);
}

.form-input{
   text-align: left;
   width: 100%;
}

.form-input input, .form-input textarea{
   width: calc(100% - 30px);
   resize: none;
   outline: none;
   box-shadow: 0 0 8px 0 var(--theme-shadow-2);
   background: var(--theme-bg);
   border: none;
   padding: 10px;
   margin-top: 5px;
   border-radius: 8px;
   transition: all 100ms ease-in;
}

.form-input input:focus, .form-input textarea:focus{
   box-shadow: 0 0 8px 0 var(--theme-accent);
   background: var(--theme-card);
}

.form-input textarea{
   width: calc(100% - 30px);
   height: 150px;
}

.show-sidebar {
   margin-top:20px; 
 }

.createStartUp{
   width: 32vw;
}

.scrollable-form {
   max-height: 65vh;
   overflow-y: auto;
   overflow-x: hidden;
}

.scrollable-form::-webkit-scrollbar{
   width: 10px;
}

.login-container{
   display:column;
   align-items: center;
   width: 100%;
}

.turtle-button {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 90%;
   margin-bottom: 5px;
   text-align: center; 
 }

 .turtle-button-2 {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 90%;
   margin-bottom: 5px;
   text-align: center; 
   border-radius: 8px;
   padding: 12px;
   outline: none;
   border: 2px solid var(--theme-card);
   cursor: pointer;
   transition: none 0.2s ease-in-out;
   transition-property: filter,box-shadow;
   background-color: rgb(123, 204, 151);  
 }


.image{
   height: 40px;
   justify-content: center;
   margin-left: 5px;
}

.separator {
   display: flex;
   align-items: center;
   margin: 20px 0; 
 }

 .line {
   flex: 1;
   height: 1px;
   background-color: #4CAF50; 
   margin: 0 2px; 
 }

 .separator span {
   padding: 0 10px; 
 }

.email-input, .password-input{
   width: 90%;
   resize: none;
   outline: none;
   box-shadow: 0 0 8px 0 var(--theme-shadow-2);
   background: var(--theme-bg);
   border: none;
   padding: 10px;
   margin-top: 5px;
   border-radius: 8px;
   transition: all 100ms ease-in;
   box-sizing: border-box;
} 

.email-input:focus, .password-input:focus{
   outline: none;
   box-shadow: 0 0 8px 0 var(--theme-accent);
   background: var(--theme-card);
}

.sign-up{
   font-size: 16px;
}

.sign-up-link {
   cursor: pointer;
   text-decoration: underline;
   color: blue; 
 }


 .signup-form {
   max-height: 45vh;
   overflow-y: auto;
   overflow-x: hidden;
   display:flex;
   flex-direction:column;
   
}

.signup-form::-webkit-scrollbar{
   width: 10px;
}


.input-group {
   display:flex;
   flex-direction:column;
   align-items: center;
   gap:12px;
}

.center{
   display:flex;
   flex-direction:column;
   align-items: center;
}
 