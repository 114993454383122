.doc-input {
   width: calc(100% - 20px);
   min-height: 100px;
   resize: none;
   box-shadow: 0px 0px 4px 0px var(--theme-border); 
   background-color: var(--theme-background);
   outline: none;
   transition: none 0.1s ease-in;
   transition-property: transform, box-shadow, border;
   transform: scale(0.96);
   padding: 8px;
   color: var(--theme-text);
   border: none;
}

.go {
   width: calc(100% - 20px);
   margin: 5px 10px;
}

.doc-input:focus {
   border: 1px solid var(--theme-accent);
   box-shadow: 0px 0px 5px 0px var(--theme-border);
   transform: scale(0.98);

}

.doc-nav {
   max-width: 300px;
   margin-left: 40px;
   height: calc(100% - 90px);
   overflow-y: auto;
   position: fixed;
   right: 0px;
   top: 20px;
   padding: 20px;
}

.doc-content {
   margin-right: 320px;
   overflow-y: auto;
   height: 100%;
}

.doc-nav-item {
   margin: 5px 0;
   box-shadow: 0px 0px 4px 0px var(--theme-border);
   padding: 5px;
   border-radius: 4px;
   cursor: pointer;
   transition: color 0.1s ease-in-out;
}

.doc-nav-item:hover {
   color: var(--theme-accent-2);
}


.action-go {
   padding: 20px;
   float: right;
}

h3:has(+.doc-input){
   text-align: center;
}