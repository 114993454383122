@font-face {
   font-family: 'Galactic Indifference';
   src: url("../static/assets/GlacialIndifference-Regular.otf");
}

@font-face {
   font-family: 'Galactic Indifference Bold';
   src: url("../static/assets/GlacialIndifference-Bold.otf");
}

* {
   font-family: 'Galactic Indifference', sans-serif;
   overflow-wrap: break-word;
   transition: background 0.1s ease-in-out;
   ;
}

html,
#root,
body,
.challenger,
.doc {
   height: 100%;
   /* cursor   :url(https://cdn.custom-cursor.com/db/8994/32/minimal-turtle-cursor.png) , default!important */
}

.content {
   height: calc(100% - 40px);
}

body.dark {
   --theme-bg:#222;
   --theme-text:white;
   --theme-bg-2:#272727;
   --theme-card:#272727;
   --theme-text-2:var(--theme-accent);
   --theme-text-3:var(--theme-accent);
   --theme-accent:#7bcc97;
   --theme-accent-2:#88cc7b;
   --theme-shadow:rgba(19, 19, 19, 0.382);
   --theme-shadow-2:rgba(14, 14, 14, 0.779);
   --theme-comp:#cc7bb0;
   --theme-border:var(--theme-accent);
   

}

body:not(.dark) {
   --theme-bg:#f6ebed;
   --theme-card:#fbe8f4;
   --theme-text:#222;
   --theme-accent:#7bcc97;
   --theme-accent-2:#56b171;
   --theme-shadow:rgba(0,0,0,0.2);
   --theme-shadow-2:rgba(0, 0, 0, 0.3);
   --theme-bg-2:var(--theme-accent);
   --theme-comp:#cc7bb0;
   --theme-text-2:#222;
   --theme-text-3:#444;
   --theme-border:var(--theme-shadow-2)
}

body {
   background-color: var(--theme-bg);
   color: var(--theme-text);
   margin: 0;
}

/* width */
::-webkit-scrollbar {
   width: 17px;
}

/* Track */
::-webkit-scrollbar-track {
   background: #0000 ;
}

/* Handle */
::-webkit-scrollbar-thumb {
   background: var(--theme-accent);
   border-radius: 7px;
   border: 4px solid #0000;
   background-clip: padding-box;
   box-shadow: inset 0 0 6px 0px rgba(34, 34, 34, 0.608);
   transition: all 0.1s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   border: 4px solid #0000;
   box-shadow: inset 0 0 6px 0px rgb(34, 34, 34);
   background: var(--theme-accent-2);
   background-clip: padding-box;

}