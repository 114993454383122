*{
   scroll-behavior: smooth;
}

#h-navbar {
   background-color: #00000000;
   color: #ffffff;
   font-size: 20px;
   font-weight: bold;
   padding: 15px;
}

#h-nav-items a {
   color: #ffffff;
   text-decoration: none;
   padding: 15px;
   font-size: 20px;
   font-weight: bold;
   transition: color 0.1s ease-in-out;
   scroll-behavior: smooth;
}

.dd-items{
   background-color: #1c4129;
   color: white;
}
.dd-items:hover{
   background-color: white;
}

#h-nav-items a:hover {
   color: white;
   background-color: rgba(51, 82, 51, 0.806);
   cursor: pointer;
}

#h-hero {
   color: white;
   margin: 0;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   z-index: -1;
}

#h-hero::before {
   content: "";
   position: absolute;
   background-size: cover;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-image: linear-gradient(#0000, #213d2e),
      url('../static/assets/bg.png');
   filter: brightness(0.7);
   z-index: -1;
   box-shadow: 0 0 500px 500px #213d2e;
}

#hero-spacer {
   margin-bottom: calc(100vh);
   display: block;
}

#h-hero-text-cont {
   margin-top: 36vh;
   margin-left: 6vw;
   border-radius: 10px;
}

#h-hero-heading {
   font-size: 12vh;
   letter-spacing: 3px;
   margin-bottom: 10px;
   /* font-size: 72px;   */
   background: -webkit-linear-gradient(#a0c4a6, #fff);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}

#h-hero-subheading {
   font-size: 3vh;
   letter-spacing: 2px;
   margin-top: 0;
   padding-left: 5px;
   max-width: 50vw;
}

#h-hero-line {
   width: 50vw;
   height: 1px;
   background-color: white;
   margin-top: 20px;
   display: block;
}

.h-text {
   margin: 20px 100px;
}

#h-about {
   padding-top: 0;
   margin-bottom: 80px;
   color: #ffffffd1;
}

.h-text h1 {
   font-size: 5vh;
   margin-bottom: 20px;
   margin-top: 0;
   border-bottom: 1px solid #ffffffd1;
   width: fit-content;
   padding: 0 10px 2px 0;
}

.h-text p {
   font-size: 3vh;
   line-height: 1.2;
   margin-bottom: 20px;
   max-width: 80vw;
   
}


.h-card {
   background-color: #ffffffc7;
   border-radius: 10px;
   padding: 25px;
   margin: 20px 100px;
   box-shadow: 0 0 10px 0 #0000001f;
   transition: box-shadow 0.2s ease-in-out;
   backdrop-filter: blur(7px);

}

.h-card img {
   border-radius: 10px;
   width: 100%;
   margin: 0 10px;
   box-shadow: 0 0 8px 0 #22222230;
}

#h-graph-text {
   padding: 30px;
   font-size: 2.8vh;
}

#h-graph h1 {
   margin-top: 0;
   font-size: 5vh;
   margin-left: 20px;
   border-bottom: 1px solid #000;
   width: fit-content;
   margin-bottom: 5vh;
   ;
}

#h-graph-text p {
   margin-left: auto;
   margin-right: auto;
   max-width: 70vw;
   text-align: center;
}

#h-nav-logo {
   height: 50px;
   border-radius: 8px;
}

#h-hero-logo {
   height: 100px;
   margin: 0 10px;
   vertical-align: text-top;
}


#h-hero-earth {
   position: absolute;
   right: 120px;
   top: 150px;
   height: 450px;
}

.image-cycle .inactive {
   filter: brightness(0.5) grayscale(1);
}

.image-cycle .inactive-cont {
   display: inline-block;
   position: relative;
   width: calc(25vw);
}

.image-cycle .inactive-cont:first-child {
   margin: 0 20px;
}

.image-cycle .image-btn {
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100px;
   height: 100px;
   transform: translate(-50%, -50%);
   background-color: #213d2e5c;
   color: white;
   border: none;
   border-radius: 50%;
   cursor: pointer;
   transition: background-color 0.2s ease-in-out;
   font-size: 3vh;
}

.image-cycle .image-btn:hover {
   background-color: #213d2e8d;
}

.image-cycle>*:not(.inactive-cont) {
   width: calc(25vw);
}

.h-content .h-img {
   width: 40vw;
   border-radius: 50px;
   filter: drop-shadow(0 10px 10px #23232396);
}

.h-content-text {
   width: 40vw;
   margin-bottom: 20px;
   max-width: 80vw;
}

.h-content-text p {
   font-size: 3.2vh;
}

.h-content {
   color: white;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin: 30vh 10vh;
}

.h-content .h-img+.h-content-text {
   margin-left: 5vw;

}

.h-content .h-content-text+.h-img {
   margin-left: 5vw;
}


#h-main  {
   /* this is needed or the background will be offset by a few pixels at the top */
   overflow: auto;
   position: relative;
   height: 100vh;
 }
 
 #h-main::before {
    content: "";
    position: fixed;
    left: -5%;
    right: -5%;
    z-index: -1;
    
    display: block;
    /* background-image: url('../static/assets/turtle\ \(2\).jpg'); */
    background: linear-gradient(
          #092a21,
          #038562
    );
   background-size:3px;
   width: 110%;
   height: 110%;
/*  
   -webkit-filter: blur(5px);
   -moz-filter: blur(5px);
   -o-filter: blur(5px);
    -ms-filter: blur(5px); */
   /* filter: blur(1px);  */
 }
 #h-footer{
   color: white;
   text-align: center;
   padding: 10px;
   background-color: #222  ;
 }

 
.nav-toggle {
   cursor: pointer;
   font-size: large;
   display: none;
}

#h-navbar{
   position: sticky;
   display: flex;
   justify-content: space-between;
   height: 80px;
   z-index: 1;
   width: 100%;
   max-width: 1300px;
}

#h-nav-items{
      display: flex;
      align-items: center;
      list-style: none;
      text-align: center;
}


@media only screen and (max-width:750px) {
   .nav-toggle {
      display: block;
      float: right;
      margin-top: 10px;
   }

   #h-nav-items {
      width: 0;
      position: absolute;
      right: 0px;
      border-radius: 8px;
      display: block;
      top:70px;
      height: auto;
      background-color: #111111a4;
      backdrop-filter: blur(10px);
   }
   #h-nav-items.open {
      width: 300px;
      right: 0;

   }
   #h-nav-items a{
      display: block;
      margin: 10px 0;
   }
   /* .nav-list li a{
      display: block;
      margin: 0;
   } */ 
}
.h-image:hover{
   transform: scale(1.1);
   transition: all 0.4s;
}




/* TEST */
.navbar {
   overflow: hidden;
   background-color: black;
 }

 .navbar a {
   float: left;
   font-size: 16px;
   color: white;
   text-align: center;
   padding: 14px 16px;
   text-decoration: none;
 }

 .dropdown {
   float: left;
   overflow: hidden;
 }
 

 .dropdown .btn {
   font-size: 20px;  
   border: none;
   outline: none;
   color: white;
   padding: 14px 16px;
   background-color: inherit;
   font-family: inherit;
   margin: 0;
 }

 .navbar a:hover, .dropdown:hover .btn {
   background-color: #1c4129;
 }

 .dropdown-menu {
   display: none;
   position: absolute;
   background-color: #f9f9f9;
   min-width: 160px;
   box-shadow: 0px 8px 16px 0px #1c4129;
   z-index: 1;

    }

 .dropdown-menu a {
   float: none;
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
   text-align: left;
 }


 .dropdown-menu a:hover {
   background-color: #fff;
 }

 .dropdown:hover .dropdown-menu {
   display: block;
   background-color: #fff;
 }

 #partner-link:hover{
   background-color: #fff;
 }