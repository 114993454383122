.content{
   position: relative;  
}

.timeline{
   display: flex;
   flex-wrap: wrap;

}
.timeline-item{
   padding: 20px;
   border-radius: 8px;
   box-shadow: 0px 0px 8px 0px var(--theme-shadow-2);
   box-shadow: 0px 0px 4px 0px var(--theme-border);
   cursor: pointer;
   margin: 15px 8px;
   transition: none 0.2s ease-in-out;
   transition-property: filter,box-shadow;
   background-color: var(--theme-card);
   display: inline-block;
   width: 250px;
  
}
.timeline-item:hover{
   box-shadow: 0 0 6px 0px var(--theme-accent-2);
   filter: brightness(0.95);
}
.turtle-button{
   border-radius: 8px;
   padding: 12px;
   outline: none;
   border: 2px solid var(--theme-accent);
   background: var(--theme-bg);
   cursor: pointer;
   transition: none 0.2s ease-in-out;
   transition-property: filter,box-shadow;
   color: var(--theme-accent-2);
}

body .turtle-button:hover{
   filter: brightness(0.9);
   box-shadow: 0 0 12px 0 var(--theme-shadow)
}

body.dark .turtle-button:hover{
   filter: brightness(1.2);

}

.turtle-button:focus{
   filter: brightness(0.9);
}


.plan-card{
   padding: 15px;
   border-radius: 8px;
   box-shadow: 0px 0px 4px 0px var(--theme-border);
   /* border: 2px solid var(--theme-border); */
   margin: 15px 0;
   background-color: var(--theme-card);
   white-space: pre-line;
}

.plan-card h5{
   margin: 0 0 10px 0;
   font-size: large;
}

.plan-card p{
   margin: 0;  
}

.meta-box{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}

.meta-box>div{
   width: 30%;
   height: 25%;
   background: var(--theme-card);
   box-shadow: 0 0 4px 0 var(--theme-shadow-2);
   margin: 10px 0;
   padding: 15px;
   border-radius: 8px;
   text-align: center;
   box-shadow: 0px 0px 4px 0px var(--theme-border);
}
.meta-box>div>h5{
   margin-top: 0;
}
.meta-box>div>h1{
   font-size: 42px;
   margin: 0;
   margin-bottom: 20px;
}