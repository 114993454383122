.card{
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 var(--theme-shadow);
      margin: 20px 35px;
      max-width: 300px;
      padding: 20px 40px;
      min-height: 350px;
      text-align: center;
      font-size: larger;
      display: grid;

      flex-basis: 0;
      flex-grow: 1;

      cursor: pointer;
      transition: none 0.2s ease-in-out;
      transition-property: filter,box-shadow;

      user-select: none;

      color: black;

      /* margin: auto !important; */

}

.card-10{
   background-color: rgb(170, 248, 237);
   ;
}

.card-5{
   background-color: rgb(252, 248, 180);
}

.card-0{
   background-color: #ceee8d;
}
.card{
   transition: none 0.2s ease-in-out;   
   transition-property: transform,box-shadow,filter;
   filter: brightness(0.89);
}
.card:hover{
   box-shadow: 0 8px 16px 0px var(--theme-shadow-2);
   filter: brightness(0.81);
   transform: translateY(-5px);
}

.card-description{
   /* margin-top: -20px; */
   font-size: 1.25em;
   text-align: left;
   line-height: 1.25em;
   margin: auto;
}


.cardset{
   display: flex;
   margin-top: 5%;
}


#title{
   text-align: center;
   font-size: 32px;
   font-family: "Galactic Indifference Bold";
   margin: 10px 0;
   border-bottom: 1px solid var(--theme-border);
   padding-bottom: 5px;
   border-radius: 8px;

   text-transform: uppercase;
}